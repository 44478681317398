<template>
  <div>
    <h3 style="margin-bottom: 1em">
      <!--<vs-checkbox
        v-model="filters.inova"
        @click="loadKanBan()"
        style="display: inline-flex"
        ><span v-if="filters.inova">Kanban - Desenvolvimento</span
        ><span v-else>Kanban - Requisições</span></vs-checkbox
      >-->
      <select
        name="KanbanType"
        v-model="filters.inova"
        class="mb-6 select1"
        @change="loadKanBan()"
      >
        <option key="1" value="true">KanBan - Desenvolvimento</option>
        <option key="2" value="false">KanBan - Requisições</option>
      </select>

      <vs-button
        type="border"
        size="small"
        icon-pack="feather"
        icon="icon-filter"
        @click="openPopUpFilters()"
        color="warning"
        style="display: inline; margin-left: 1em"
      ></vs-button>
    </h3>
    <kanban-board :stages="stages" :blocks="blocks" @update-block="updateBlock">
      <div v-for="stage in stages" :slot="stage" :key="stage">
        <h2>{{ stage }}</h2>
        <p>
          <strong>{{ count(stage) }} </strong>ticket(s)
        </p>
      </div>
      <div
        v-for="block in blocks"
        :slot="block.id"
        :key="block.id"
        @click="openPopUp(block)"
        class="selectPointer"
      >
        <div>
          <strong v-if="block.code">
            {{ block.code }} <span v-if="block.duplicated">*</span></strong
          >
        </div>
        <div>
          <p>{{ block.description }}</p>
        </div>
        <div class="classification">
          <p v-bind:style="{ color: block.color }">
            {{ block.classification.name }}
          </p>
          <p>{{ block.dateLabel }}: {{ block.date }}</p>
        </div>
      </div>
    </kanban-board>

    <vs-popup
      :active.sync="showOrderDetails"
      :title="title"
      v-if="showOrderDetails"
      fullscreen
    >
      <vs-card
        ><vs-tabs>
          <vs-tab label="Projeto">
            <div slot="header">
              <h2>Ticket Nro. {{ orderSelected.code }}</h2>
            </div>
            <div class="card">
              <vs-row vs-w="12">
                <vs-col vs-w="6">
                  <h3 v-if="orderSelected.inova">Desenvolvimento</h3>
                  <h3 v-else>Requisição</h3>
                  <p>
                    Requisição criada por
                    {{ orderSelected.createdUser.name }} em
                    {{ orderSelected.created }} - última atualização em
                    {{ orderSelected.updated }}
                  </p>
                  <p v-if="orderSelected.duplicated" class="duplicated">
                    <strong>*** Requisição duplicada! ***</strong>
                  </p>
                  <p>
                    {{ orderSelected.phase.name }} -
                    {{ orderSelected.classification.name }} -
                    {{
                      orderSelected.makeUnit
                        ? orderSelected.makeUnit.name
                        : "Sem unidade atribuída"
                    }}
                  </p>
                  <p>
                    Código SAP:
                    {{
                      orderSelected.project.code
                        ? orderSelected.project.code
                        : "Não possui"
                    }}
                  </p>
                  <p v-if="orderSelected.project.sapValue">
                    Valor SAP: R$
                    {{ orderSelected.project.sapValue }}
                  </p>
                  <p v-if="orderSelected.project.estimatedValue">
                    Valor Unitário: R$
                    {{
                      parseFloat(orderSelected.project.estimatedValue).toFixed(
                        2
                      )
                    }}
                  </p>
                  <p>Quantidade: {{ orderSelected.qtd }}</p>
                  <p>
                    Valor do lote: R$
                    {{
                      (
                        orderSelected.project.estimatedValue * orderSelected.qtd
                      ).toFixed(2)
                    }}
                  </p>
                  <p v-if="orderSelected.project.family">
                    Família:
                    {{
                      orderSelected.project.family
                        ? orderSelected.project.family.name
                        : "-"
                    }}
                  </p>
                  <p>Descrição: {{ orderSelected.project.description }}</p>

                  <p v-if="orderSelected.impact">
                    Impacto: {{ orderSelected.impact.name }}
                  </p>
                  <p>
                    Projetista:
                    {{
                      orderSelected.project.designerUser
                        ? orderSelected.project.designerUser.name
                        : "Sem projetista"
                    }}
                  </p>
                  <p>
                    Produtor:
                    {{
                      orderSelected.producerUser
                        ? orderSelected.producerUser.name
                        : "Sem produtor"
                    }}
                  </p>
                  <p>
                    Supervisor:
                    {{
                      orderSelected.supervisorUser
                        ? orderSelected.supervisorUser.name
                        : "Sem supervisor"
                    }}
                  </p>
                  <p v-if="orderSelected.reservationCode">
                    Código de reserva:
                    {{ orderSelected.reservationCode }}
                  </p>
                  <p v-if="orderSelected.transferCode">
                    Código de transferência:
                    {{ orderSelected.transferCode }}
                  </p>
                  <p>{{ orderSelected.dateLabel }}: {{ orderSelected.date }}</p>
                  <fieldset v-if="hasPermission != -1">
                    <legend>Projeto {{ orderSelected.project.code }}</legend>
                    <h3>{{ orderSelected.project.name }}</h3>
                    <vs-row vs-w="12">
                      <vs-col vs-w="6" class="spacing">
                        <p>
                          Manufatura:
                          <select
                            v-validate="'required'"
                            name="manufacture"
                            v-model="orderSelected.project.manufacture.id"
                            label="Manufatura"
                            class="w-full mb-6 select1"
                            data-vv-as="Manufatura"
                          >
                            <option
                              :key="index"
                              :value="item.id"
                              v-for="(item, index) in manufactureOptions"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </p>
                        <!--
                        <p>
                          Complexidade:
                          <select
                            v-validate="'required'"
                            name="complex"
                            v-model="orderSelected.project.complex"
                            label="Complexidade"
                            class="w-full mb-6 select1"
                            data-vv-as="Complexidade"
                          >
                            <option
                              :key="index"
                              :value="item.id"
                              v-for="(item, index) in complexOptions"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </p>-->
                        <!--
                        <p>
                          Material
                          <select
                            v-validate="'required'"
                            name="material"
                            v-model="orderSelected.project.material"
                            label="Material"
                            class="w-full mb-6 select1"
                            data-vv-as="Material"
                          >
                            <option
                              :key="index"
                              :value="item"
                              v-for="(item, index) in materialOptions"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </p>
-->
                        <!--<p>
                          Massa (em gramas):
                          <br />
                          <vs-input
                            type="number"
                            class="w-full mb-6"
                            v-model="orderSelected.project.mass"
                            :text="orderSelected.project.mass"
                          ></vs-input>
                        </p>
                        -->

                        <p>
                          Tempo (dias):
                          <br />
                          <vs-input
                            type="number"
                            class="w-full mb-6"
                            v-model="orderSelected.time"
                            :text="orderSelected.time"
                          ></vs-input>
                        </p>
                        <p>
                          Quantidade produzida:
                          <br />
                          <vs-input
                            type="number"
                            class="w-full mb-6"
                            v-model="orderSelected.qtdProduced"
                            :text="orderSelected.qtdProduced"
                          ></vs-input>
                        </p>
                      </vs-col>
                      <vs-col vs-w="6">
                        <p>
                          Valor de produção:
                          <br />
                          <money
                            v-model="orderSelected.productionValue"
                            class="w-full mb-6 select1"
                            data-vv-as="Valor de produção"
                          ></money>
                        </p>
                        <p>
                          Valor unitário:
                          <br />
                          <money
                            v-model="orderSelected.project.estimatedValue"
                            class="w-full mb-6 select1"
                            data-vv-as="Valor Unitário"
                          ></money>
                        </p>
                        <p>
                          <vs-button class="bt2" @click="updateProject()"
                            >Atualizar</vs-button
                          >
                        </p>
                      </vs-col>
                    </vs-row>
                  </fieldset>
                </vs-col>
                <vs-col vs-w="6" class="line">
                  <img
                    :src="orderSelected.imageUrl"
                    title="Imagem do Projeto"
                    width="90%"
                    v-if="orderSelected.project.image"
                  />
                  <p v-else>Sem imagem</p>
                </vs-col>
              </vs-row>
            </div>
          </vs-tab>
          <vs-tab label="Histórico">
            <div>
              <vs-table
                ref="table"
                pagination
                :max-items="20"
                search
                :data="orderSelected.history"
              >
                <template slot="thead">
                  <vs-th>Data</vs-th>
                  <vs-th>Evento</vs-th>
                  <vs-th>Fase</vs-th>
                  <vs-th>Usuário</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <tbody>
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td>
                        {{ tr.created }}
                      </vs-td>
                      <vs-td>
                        {{ tr.event }}
                      </vs-td>
                      <vs-td> {{ tr.phase.name }} </vs-td>
                      <vs-td>
                        {{ tr.user.name }}
                      </vs-td>
                    </vs-tr>
                  </tbody>
                </template>
              </vs-table>
            </div>
          </vs-tab>
          <vs-tab label="Comentários">
            <vs-button @click="openPrompt()" class="bt3"
              >Adicionar comentário</vs-button
            >
            <p v-if="comments.length == 0">Não há comentários cadastrados</p>
            <div :key="index" v-for="(comment, index) in comments">
              <p>
                <strong>{{ comment.description }}</strong>
              </p>
              <p>
                <small
                  >Postado por {{ comment.user.name }} em
                  {{ comment.created }}</small
                >
              </p>
              <vs-divider></vs-divider>
            </div>
          </vs-tab>
        </vs-tabs>
      </vs-card>
    </vs-popup>

    <vs-popup title="Filtros" :active.sync="showFilters" fullscreen>
      <vs-card>
        <div slot="header">
          <h3>Filtros</h3>
        </div>
        <div>
          <vs-row vs-w="12">
            <vs-col vs-w="2" class="margem">
              Unidade Solicitante
              <select
                name="classification"
                v-model="filters.requestUnitId"
                label="Unidade Solicitante"
                class="w-full mb-6 select1"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in unitOptions"
                >
                  {{ item.name }}
                </option>
              </select>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Unidade de Criação
              <select
                name="classification"
                v-model="filters.makeUnitId"
                label="Unidade de Criação"
                class="w-full mb-6 select1"
                v-on:change="loadUsers()"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in unitOptions"
                >
                  {{ item.name }}
                </option>
              </select>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Projetista
              <select
                name="designerId"
                v-model="filters.designerId"
                label="Projetista"
                class="w-full mb-6 select1"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in userOptions"
                >
                  {{ item.name }}
                </option>
              </select>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Classificação
              <select
                name="classification"
                v-model="filters.classificationId"
                label="Classificação"
                class="w-full mb-6 select1"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item.id"
                  v-for="(item, index) in classificationOptions"
                >
                  {{ item.name }}
                </option>
              </select>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Região
              <select
                name="Região"
                v-model="filters.region"
                label="Região"
                class="w-full mb-6 select1"
              >
                <option value="">Selecione...</option>
                <option
                  :key="index"
                  :value="item"
                  v-for="(item, index) in regionOptions"
                >
                  {{ item }}
                </option>
              </select>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="2" class="margem">
              Criado de
              <vs-input
                type="date"
                name="createdAtFrom"
                v-model="filters.createdAtFrom"
              ></vs-input>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Criado até
              <vs-input
                type="date"
                name="createdAtTo"
                v-model="filters.createdAtTo"
              ></vs-input>
            </vs-col>
            <vs-col vs-w="2" class="margem">
              <label for="">Código</label>
              <vs-input v-model="filters.code" />
            </vs-col>
            <vs-col vs-w="2" class="margem">
              <label for="">Material</label>
              <vs-input v-model="filters.material" />
            </vs-col>
            <vs-col vs-w="2" class="margem">
              Tipo de Kanban
              <select
                name="KanbanType"
                label="Tipo de Kanban"
                v-model="filters.inova"
                class="w-full mb-6 select1"
              >
                <option key="1" value="true">KanBan - Desenvolvimento</option>
                <option key="2" value="false">KanBan - Requisições</option>
              </select>
            </vs-col>
          </vs-row>
          <vs-row>
            <vs-col vs-w="2" class="margem">
              <vs-button @click="loadKanBan()" class="bt2">Aplicar</vs-button>
              <vs-button @click="resetFilters()" class="bt2"
                >Limpar filtros</vs-button
              >
            </vs-col>
          </vs-row>
        </div>
      </vs-card>
    </vs-popup>
    <vs-prompt
      @accept="addComment"
      @cancel="cancelComment"
      @close="cancelComment"
      :active.sync="activePrompt"
      title="Comentário"
      acceptText="Postar"
      cancelText="Cancelar"
    >
      <vs-textarea
        placeholder="Digite seu comentário aqui"
        v-model="comment"
        class="w-full mb-6"
      ></vs-textarea>
    </vs-prompt>
  </div>
</template>
<script>
import siriusAPI from "../services";
import {
  notifyError,
  notifySuccess,
  formatDateToLocale,
} from "@/utils/helpers";
import { getDataInStorage, updateDataInStorage } from "../utils/storage";

export default {
  data() {
    return {
      showFilters: false,
      stages: [],
      blocks: [],
      phasesOptions: [],
      hasPermission: -1,
      showOrderDetails: false,
      orderSelected: null,
      isAdmin: false,
      materialOptions: [],
      manufactureOptions: [],
      classificationOptions: [],
      unitOptions: [],
      regionOptions: [],
      userOptions: null,
      loggedUser: null,
      filters: {
        inova: false,
        my: false,
        requestUnitId: "",
        makeUnitId: "",
        region: "",
        classificationId: "",
        designerId: "",
        createdAtFrom: null,
        createdAtTo: null,
        code: null,
        material: null,
      },
      check: false,
      complexOptions: [
        { id: "1", name: "Fácil" },
        { id: "2", name: "Médio" },
        { id: "3", name: "Difícil" },
      ],
      comments: [],
      comment: "",
      activePrompt: false,
      title: "Detalhes da requisição",
    };
  },

  mounted() {
    this.loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    const userProfile = this.loggedUser.profile.name;
    if (userProfile == "Solicitante") this.filters.my = true;
    const profiles = ["Administrador", "Projetista"];
    this.isAdmin = userProfile == profiles[0];

    let storage = getDataInStorage();
    if (storage.filters) this.filters = storage.filters;

    this.loadKanBan();
    this.loadMaterials();
    this.loadClassifications();
    this.loadUnits();
    this.loadUsers();
    this.loadRegions();
    this.loadManufactures();

    new siriusAPI()
      .getPhases()
      .then((res) => {
        this.phasesOptions = res.data;
      })
      .catch((error) => {
        notifyError(this, error);
      });
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    cancelComment() {
      this.comment = "";
      this.showOrderDetails = true;
    },
    loadComments(uuid) {
      this.comments = [];
      this.$vs.loading();
      new siriusAPI()
        .getComments(uuid)
        .then((res) => {
          this.comments = res.data;
          this.comments = this.comments.map((comment) => ({
            ...comment,
            created: formatDateToLocale(comment.createdAt),
            updated: formatDateToLocale(comment.updatedAt),
          }));
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de comentários: " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    addComment() {
      const data = {
        description: this.comment,
      };
      this.$vs.loading();
      new siriusAPI()
        .addComment(this.orderSelected.uuid, data)
        .then(() => {
          notifySuccess(this, "Comentário adicionado com sucesso");
          this.$vs.loading.close();
          this.comment = "";
          this.showOrderDetails = true;
          this.loadComments(this.orderSelected.uuid);
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(this, "Erro ao tentar inserir um comentário: " + error);
        });
    },
    openPrompt() {
      this.showOrderDetails = false;
      this.activePrompt = true;
    },

    openPopUpFilters() {
      this.showFilters = true;
    },
    loadClassifications() {
      new siriusAPI()
        .getClassifications()
        .then((res) => {
          this.classificationOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de classificação: " + error
          );
        });
    },
    loadManufactures() {
      new siriusAPI()
        .getManufactures()
        .then((res) => {
          this.manufactureOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de manufatura: " + error
          );
        });
    },

    loadRegions() {
      new siriusAPI()
        .getRegions()
        .then((res) => {
          this.regionOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de região: " + error
          );
        });
    },
    loadUsers() {
      this.$vs.loading();
      new siriusAPI()
        .getUsersWithParams(null, this.filters.makeUnitId, 3)
        .then((res) => {
          this.userOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de usuários " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    loadUnits() {
      new siriusAPI()
        .getUnits()
        .then((res) => {
          this.unitOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de unidades " + error
          );
        });
    },
    resetFilters() {
      this.filters = {
        requestUnitId: "",
        makeUnitId: "",
        region: "",
        classificationId: "",
        designerId: "",
        createdAtFrom: null,
        createdAtTo: null,
        inova: false,
        code: null,
        material: null,
      };
      this.loadKanBan();
    },
    async openPopUp(order) {
      this.orderSelected = null;
      //this.orderSelected = order;
      await this.getOrder(order.id);
      this.showOrderDetails = true;
      this.loadComments(order.uuid);
    },
    count(stage) {
      let count = 0;
      this.blocks.forEach((element) => {
        if (element.status == stage) {
          count++;
        }
      });
      return count;
    },
    async updateBlock(id, status) {
      await this.getOrder(id);
      if (this.hasPermission != -1) {
        const phase = this.phasesOptions.find((fase) => fase.name == status);
        const block = this.blocks.find((block) => block.id == id);

        if (
          (phase.index < block.phase.index && block.project.inova) ||
          (phase.indexOrder < block.phase.indexOrder && !block.project.inova)
        ) {
          notifyError(this, "Não é permitido retornar a status anteriores!");
          this.loadKanBan();
          return;
        }
        if (
          (phase.index - block.phase.index != 1 && block.project.inova) ||
          (phase.indexOrder - block.phase.indexOrder != 1 &&
            !block.project.inova)
        ) {
          notifyError(this, "Não é permitido pular etapas!");
          this.loadKanBan();
          return;
        }

        const payload = {
          phaseId: phase.id,
        };
        this.$vs.loading();
        await new siriusAPI()
          .updatePhase(block.uuid, payload)
          .then(() => {
            notifySuccess(this, "Status atualizado com sucesso");
            this.loadKanBan();
          })
          .catch((error) => {
            notifyError(this, "Erro ao tentar atualizado o status: " + error);
          })
          .finally(() => {
            this.$vs.loading.close();
          });
      } else {
        notifyError(this, "Você não tem permissão para esta operação.");
        this.loadKanBan();
      }
    },

    async getOrder(orderId) {
      this.$vs.loading();
      await new siriusAPI()
        .getOrder(orderId)
        .then((res) => {
          this.orderSelected = res.data;
          this.title = "Detalhes - " + this.orderSelected.code;
          this.orderSelected.created = formatDateToLocale(
            this.orderSelected.createdAt
          );
          this.orderSelected.updated = formatDateToLocale(
            this.orderSelected.updatedAt
          );
          if (this.orderSelected.project.image) {
            this.orderSelected.imageUrl =
              process.env.VUE_APP_URL_IMAGES + this.orderSelected.project.image;
          }
          if (this.orderSelected.producerUser) {
            if (this.orderSelected.producerUser.id == this.loggedUser.id) {
              this.hasPermission = true;
            }
          }
          if (this.orderSelected.project.designerUser) {
            if (
              this.orderSelected.project.designerUser.id == this.loggedUser.id
            ) {
              this.hasPermission = true;
            }
          }
          if (this.orderSelected.supervisorUser) {
            if (this.orderSelected.supervisorUser.id == this.loggedUser.id) {
              this.hasPermission = true;
            }
          }
          if (!this.orderSelected.project.manufacture) {
            this.orderSelected.project.manufacture = { id: 0 };
          }
          if (this.orderSelected.necessityAt) {
            this.orderSelected.dateLabel = "Necessidade";
            this.orderSelected.date = formatDateToLocale(
              this.orderSelected.necessityAt
            );
          } else {
            this.orderSelected.dateLabel = "Entrega";
            this.orderSelected.date = formatDateToLocale(
              this.orderSelected.deliveryAt
            );
          }
        })
        .catch(() => {
          notifyError(
            this,
            "Não foi possível recuperar informações da requisição!"
          );
          this.showOrderDetails = false;
        })
        .finally(() => {
          this.$vs.loading.close();
        });
      this.$vs.loading();
      await new siriusAPI()
        .getOrderHistory(this.orderSelected.uuid)
        .then((res) => {
          this.orderSelected.history = res.data.map((history) => ({
            ...history,
            created: formatDateToLocale(history.createdAt),
          }));
        })
        .catch(() => {
          notifyError(
            this,
            "Não foi possível recuperar informações da requisição!"
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    async loadMaterials() {
      this.$vs.loading();
      new siriusAPI()
        .getMaterials()
        .then((res) => {
          this.materialOptions = res.data;
          if (this.materialOptions[0] == undefined)
            notifyError(this, "Não há materiais cadastrados!");
          this.$vs.loading.close();
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de materiais: " + error
          );
          this.$vs.loading.close();
        });
    },

    updateProject() {
      const orderUuid = this.orderSelected.uuid;
      const payload = {
        qtd: this.orderSelected.qtd,
        qtdProduced: this.orderSelected.qtdProduced, //conferir
        time: this.orderSelected.time,
        productionValue: this.orderSelected.productionValue,
        project: {
          name: this.orderSelected.project.name,
          manufactureId: this.orderSelected.project.manufacture.id,
          kpi: this.orderSelected.project.kpi,
          sapValue: this.orderSelected.project.sapValue,
          complex: this.orderSelected.project.complex,
          estimatedValue: this.orderSelected.project.estimatedValue,
          description: this.orderSelected.project.description,
          materialId: this.orderSelected.project.material
            ? this.orderSelected.project.material.id
            : "",
          familyId: this.orderSelected.project.family
            ? this.orderSelected.project.family.id
            : "",
          equipmentId: this.orderSelected.project.equipment
            ? this.orderSelected.project.equipment.id
            : "",
          mass: this.orderSelected.project.mass,
        },
      };
      this.$vs.loading();

      new siriusAPI()
        .updateProject(payload, orderUuid)
        .then(() => {
          notifySuccess(this, "Projeto atualizado com sucesso!");
          this.showOrderDetails = false;
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          notifyError(
            this,
            "Erro ao tentar carregar atualizar o projeto: " + error.message
          );
        });
    },

    async loadKanBan() {
      updateDataInStorage({ filters: this.filters });
      this.$vs.loading();
      this.blocks = [];
      this.stages = [];
      if (this.filters.code)
        this.filters.code = "%25" + this.filters.code + "%25";
      if (this.filters.material)
        this.filters.material = "%25" + this.filters.material + "%25";
      new siriusAPI()
        .getKanBan(this.filters)
        .then((res) => {
          let result = res.data;
          this.stages = Object.keys(result);
          let orders = Object.values(result);
          orders.forEach((element) => {
            element.forEach((order) => {
              this.blocks.push({
                id: order.id,
                status: order.phase.name,
                phase: order.phase,
                description: order.description,
                code: order.code,
                unit: order.makeUnit,
                classification: order.classification,
                color: order.classification.color,
                project: order.project,
                uuid: order.uuid,
                created: formatDateToLocale(order.createdAt),
                updated: formatDateToLocale(order.updatedAt),
                duplicated: order.duplicated,
                date: order.necessityAt
                  ? formatDateToLocale(order.necessityAt)
                  : formatDateToLocale(order.deliveryAt),
                dateLabel: order.necessityAt ? "Necessidade" : "Entrega",
              });
            });
          });
        })
        .catch((error) => {
          notifyError(this, "Erro ao carregar o kanban: " + error);
        })
        .finally(() => {
          this.$vs.loading.close();
          this.showFilters = false;
        });
    },
  },
};
</script>
<style scoped>
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.spacing {
  padding-right: 3%;
}
.margem {
  margin-right: 3%;
}

fieldset {
  border: 0.8px solid #b6b2b2;
  padding: 0.5em;
}
fieldset legend {
  font-weight: bold;
}
.bt {
  margin-right: 1em;
}
.bt3 {
  margin-bottom: 1em;
}
.card {
  margin-bottom: 3em;
}
.line {
  text-align: center;
}

.bt2 {
  margin-top: 0.5em;
  margin-right: 0.5em;
}
.duplicated {
  color: red;
}
.selectPointer {
  cursor: pointer;
}
</style>

<style lang="scss">
@import "@/assets/kanban.scss";
</style>


